import React, { useState} from 'react';
import { Link } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { renderRichText } from "gatsby-source-contentful/rich-text"

import '../theme/home.scss';
import CustomFooter from '../components/CustomFooter';
import Twitter from '../assets/icons/twitter-x.svg';
import Thumb from '../assets/images/thumb.jpg';
import Face from '../assets/icons/icon-face.svg';
import Insta from '../assets/icons/icon-insta.svg';
import Linkedin from '../assets/icons/icon-link.svg';
import Tw from '../assets/icons/icon-tw.svg';
import Spotify from '../assets/icons/icon-spotify.svg';

import HeaderLayout from '../components/layout/HeaderLayout';
import ExternalLink from '../components/layout/ExternalLink';
import GdprCookies from '../components/GdprCookies';
import ModalSuscribe from '../components/layout/ModalSuscribre';

import { useHomePosts } from '../operations/queries/homePostsNew';
import { useSemanarioFinancieroPosts } from '../operations/queries/semanarioFinancieroPosts';
import { usePresentacionAnalisisPosts } from '../operations/queries/presentacionAnalisisPosts';
import { useGenericoPosts } from '../operations/queries/genericoPosts';
import { HomePosts as HomePostsProps } from '../models/HomePosts';


export default function Newhome(): JSX.Element {

    const data: HomePostsProps = useHomePosts();
    const semanarioFinanciero = useSemanarioFinancieroPosts();
    const presentacionAnalisisPosts = usePresentacionAnalisisPosts();
    const genericoPosts = useGenericoPosts();

    const semanario = semanarioFinanciero[0]
    const presentacion = presentacionAnalisisPosts[0]
    const fundamental = data.carteraModelo[0]

    const highlights = data.listaHigh

    const comentarioSemanal = data.comentarioSemanal[0] ? data.comentarioSemanal[0] : false

    // console.log("lista data")

    const [section, setSection] = useState("noticias");

    //functions modal suscribe
    const [open, setOpen] = useState(false);
    function openSuscribe(){
        setOpen(true);
    }
    function closeSuscribe(){
        setOpen(false);
    }

    console.log('highlights[0]')
    console.log(highlights[0])

    let highlightImg: any = undefined;

    try {
        highlightImg = highlights[0].fluid.gatsbyImageData.images.fallback.src;
    } catch (error) {
        try {
            highlightImg = highlights[0].fluid.gatsbyImageData.images.sources[0].srcSet;
        } catch (error2) {
            highlightImg = "https://vectoranalisis.mx/static/images/vector/analisis.png";
        }
    }

    return (
        <>
            <HeaderLayout setSection={setSection} section={section} tab={true} mb={true}/>
            <div className='home-wrapper'>
                <section className='home-metro'>
                    <div className={(section== "noticias" ?"active ":"hidden ")+'home-metro-wrap'}>

                        <div className='highlights'>
                            <div className='hig-title'>
                                <h1>De último momento</h1>
                                <div>
                                    <Link href="/todos" className='cat-link'>Ver histórico</Link>
                                </div>
                            </div>
                            <div className='highlights-wrap'>
                                <div className='hero'>
                                    <div className='hero-img'>
                                        <Link href={highlights[0].slug} className="title-link">
                                            <div className="hero-bg" style={{backgroundImage:"url("+highlightImg+")" }}></div>
                                            <div className="hero-filter"></div>
                                        </Link>
                                    </div>
                                    <div className='hero-content'>
                                        <p className='cat-link pill-azul'>{highlights[0].category}</p>
                                        <Link href={highlights[0].slug} className='title-link'>
                                            <p className='title'>{highlights[0].title}</p>
                                        </Link>
                                        <p className='date'><span>{highlights[0].author}</span> <span>{highlights[0].date}</span></p>
                                        <p className='resumen'>{highlights[0].description.raw ? renderRichText(highlights[0].description) :highlights[0].description}</p>
                                        <Link href={highlights[0].slug} className='link-mas'>Ver más</Link>
                                    </div>
                                </div>


                                <div className='featured-items'>
                                    {
                                        highlights && highlights.map((node, index)=>{
                                            if(index > 0 && index < 3 ){
                                            return(
                                                <div>
                                                    <div className="hero-img">
                                                        <Link href={node.slug}>
                                                            <div className="hero-bg" style={{backgroundImage:"url("+node.fluid.gatsbyImageData.images.fallback.src+")" }}></div>
                                                            <div className="hero-filter"></div>
                                                        </Link>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div>
                                                            <p className='cat-link pill-azul'>{node.category}</p>
                                                            <Link className='title-link' href={node.slug}>
                                                                <p className='title'>{node.title}</p>
                                                            </Link>
                                                            <p className='date'><span>{node.author}</span> <span>{node.date}</span></p>
                                                            <Link href={node.slug} className='link-mas'>Ver más</Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                            }
                                        })
                                    }

                                </div>
                                { highlights &&
                                    <div className='diario-internacional'>
                                        <div className="diario-internacional-wrap">
                                            <div className='diario-content'>
                                                <p className='cat-link pill-azul'>{highlights[3].category}</p>
                                                <Link className='title-link black' href={highlights[3].slug}>
                                                    <p className='titulo'>{highlights[3].title}</p>
                                                </Link>
                                                <p className='resumen'>{highlights[3].description.raw ? renderRichText(highlights[3].description) :highlights[3].description}</p>
                                                <p className='date'><span>{highlights[3].author}</span> <span>{highlights[3].date}</span></p>
                                                <Link href={highlights[3].slug} className='link-mas'>Ver más</Link>
                                            </div>
                                            <div className='diario-img'>
                                                <p className='cat-link pill-azul'>{highlights[3].category}</p>
                                                <Link className='title-link' href={highlights[3].slug}>
                                                    <img src={highlights[3].fluid.gatsbyImageData.images.fallback.src} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='items-news'>
                                    {
                                        highlights && highlights.map((node, index)=>{
                                            if(index > 3 ){
                                                return(
                                                    <div>
                                                        <div className="item-news-content">
                                                            <div>
                                                                <p className='cat-link pill-azul'>{node.category}</p>
                                                                <Link className='title-link black' href={node.slug}>
                                                                    <p className='title'>{node.title}</p>
                                                                </Link>
                                                                <p className='date'><span>{node.author}</span> <span>{node.date}</span></p>
                                                                <p className='resumen'>{node.description.raw ? renderRichText(node.description) :node.description}</p>
                                                                <Link href={node.slug} className='link-mas'>Ver más</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                </div>

                            </div>
                        </div>

                        <div className="estrategia">
                            <div className='hig-title'>
                                <h1>Estrategia de Inversión</h1>
                                <div>
                                    <Link href="/estrategia" className='cat-link'>Ver todo</Link>
                                </div>
                            </div>
                            <div className='items-featured-image'>
                                {
                                    data.estrategiaDeInversion && data.estrategiaDeInversion.map((node, index)=>{
                                        if(index>0){
                                            let imgSrc = '';
                                            try {
                                                imgSrc = node.fluid.gatsbyImageData.images.fallback.src;
                                            } catch (error) {
                                                try {
                                                    imgSrc = node.fluid.gatsbyImageData.images.sources[0].srcSet;
                                                } catch (error2) {
                                                    imgSrc = "https://vectoranalisis.mx/static/images/vector/analisis.png";
                                                }
                                                // imgSrc = node.fluid.gatsbyImageData.images.sources[0].srcSet;
                                            }
                                            return(
                                                <div>
                                                    <div className='img'>
                                                        <Link href={node.slug}>
                                                            <img src={imgSrc} alt="" />
                                                        </Link>
                                                    </div>
                                                    <Link className='title-link black' href={node.slug}>
                                                        <h2>{node.title}</h2>
                                                    </Link>
                                                    <p className='date'><span>{node.author}</span> <span>{node.date}</span></p>
                                                    <p className='resumen'>{node.description?.raw ? renderRichText(node.description) : node.description}</p>
                                                    <Link href={node.slug} className='link-mas'>Ver más</Link>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>

                        <div className='otros-articulos'>
                            <div className='hig-title'>
                                <h1>Otros Artículos</h1>
                                <div>
                                    <Link href="/todos" className='cat-link'>Ver todo</Link>
                                </div>
                            </div>
                            <div className='otros-items'>
                                {
                                    data.featuredArticles && data.featuredArticles.map((node, index)=>{

                                        return(
                                            <div className="otros-item">
                                                <p className='cat-link pill-azul'>{node.category}</p>
                                                <Link className='title-link black' href={node.slug}>
                                                    <h2>{node.title}</h2>
                                                </Link>
                                                <p className='date'><span>{node.author}</span> <span>{node.date}</span></p>
                                                <p className='resumen'>{node.description.raw ? renderRichText(node.description)[0].props.children[0].substr(0,180) :node.description.substr(0,180)}...</p>
                                                <Link href={node.slug} className='link-mas'>Ver más</Link>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        <div className='temas-momento'>
                            <div className='hig-title'>
                                <h1>Temas al Momento</h1>
                                <div>
                                    <Link href="/temas-del-momento" className='cat-link'>Ver todo</Link>
                                </div>
                            </div>
                            <div className="temas-items">
                                {
                                    genericoPosts && genericoPosts.map((node, index)=>{
                                        if(index > 0){
                                            return(
                                                <div className="temas-item">
                                                    <Link href={node.slug} className="title-link black">
                                                        <p className='resumen'>{node.title}</p>
                                                    </Link>
                                                    <p className='date'><span>{node.author}</span> <span>{node.date}</span></p>
                                                    <Link href={node.slug} className='link-mas'>Ver más</Link>
                                                </div>
                                            )
                                        }
                                    })
                                }

                            </div>
                        </div>
                    </div>

                    <div className={(section== "flashes" ?"active ":"hidden ")+"home-metro-aside"}>
                        {
                            data.comentarioDiario &&
                            <div className="comentario">
                                <h2>Comentario Diario</h2>
                                <Link href="/comentario-diario" className='title-link black'>
                                    <p className='comentario-title'>{data.comentarioDiario.title}</p>
                                </Link>
                                <div className='comentario-autor'>
                                    <div className='comentario-avatar-wrap'>
                                        <Avatar
                                            className="comentario-avatar"
                                            alt=""
                                            src={data.comentarioDiario?.avatar.gatsbyImageData.images.fallback.src}
                                        />
                                        <div className='comentario-content'>
                                            <p className='autor-title'>{data.comentarioDiario.author}</p>
                                            <p className='comentario-date'>{data.comentarioDiario?.date}</p>
                                        </div>
                                    </div>

                                    <div className='comentario-social'>
                                        <div className='icon-tw'>
                                            <img src={Twitter}/>
                                        </div>
                                        <ExternalLink href={"https://twitter.com/"+data.comentarioDiario.twitter}  clase='comentario-tw'>{data.comentarioDiario.twitter}</ExternalLink>
                                    </div>
                                </div>
                                <Link href="/comentario-diario" className='boton-lg azul'>Ver comentario diario</Link>

                            </div>
                        }
                        <div className="flashes">
                            <div className="flashes-title">
                                <h2>Flashes al Momento</h2>
                            </div>
                            { data.flashes && data.flashes && data.flashes.map((node,index)=>{
                                return(
                                    <>
                                    <div className='flash' key={index}>
                                        <p className='cat-link pill-verde'>{node.category}</p>
                                        <Link href={node.slug} className="title-link black">
                                            <p className='flash-title'>{node.title}</p>
                                        </Link>
                                        <p className='flash-autor'>Por: {node.author}</p>
                                        <div className='flash-footer'>
                                            <p className='flash-date'>{node.date}</p>
                                            <Link href={node.slug} className='link-mas'>Ver más</Link>
                                        </div>
                                    </div>
                                    </>
                                )
                            })
                            }
                            <Link href="/flashes" className='boton-lg verde'>Ver Flashes</Link>
                        </div>

                        {
                            comentarioSemanal &&
                            <div className="comentario">
                                <h2>Comentario Semanal</h2>
                                <Link href={comentarioSemanal.slug} className="title-link black">
                                    <p className='comentario-title'>{comentarioSemanal.title}</p>
                                </Link>
                                <div className='comentario-autor'>
                                    <div className='comentario-avatar-wrap'>
                                        <Avatar
                                            className="comentario-avatar"
                                            alt=""
                                            src={comentarioSemanal.avatar?.gatsbyImageData.images.fallback.src}
                                        />
                                        <div className='comentario-content'>
                                            <p className='autor-title'>{comentarioSemanal.author}</p>
                                            <p className='comentario-date'>{comentarioSemanal?.date}</p>
                                        </div>
                                    </div>

                                    <div className='comentario-social'>
                                        <div className='icon-tw'>
                                            <img src={Twitter}/>
                                        </div>
                                        <ExternalLink href={"https://twitter.com/"+comentarioSemanal.twitter}  clase='comentario-tw'>{comentarioSemanal.twitter}</ExternalLink>
                                    </div>
                                </div>
                                <Link href={comentarioSemanal.slug} className='boton-lg azul-claro'>Ver comentario semanal</Link>

                            </div>
                        }
                        <div className='spotify-aside'>
                            <h2>Pódcast</h2>
                            <ExternalLink href='https://open.spotify.com/show/247lnwxXr5BTShaknkAbwZ' >
                                <div className='spotify-wrap'>
                                    <div className='content'>
                                        <div className='thumb'>
                                            <img src="https://i.scdn.co/image/ab6765630000ba8a0b15d591dde07acf9a395223" alt="" />
                                        </div>
                                        <div className='body'>
                                            <p className='title'>VectorCast</p>
                                            <p className='resume'>Escucha nuestro pódcast</p>
                                        </div>
                                        <div className='icon'>
                                            <img src={Spotify} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </ExternalLink>

                        </div>

                        { fundamental &&

                            <div className="fundamental">
                                <h2>Cartera Fundamental</h2>
                                <Link href={fundamental.slug} className="title-link black">
                                    <p className='fundamental-title'>{fundamental.title}</p>
                                </Link>
                                <div className='fundamental-content'>
                                    <p>
                                        <span className='fundamental-date'>{fundamental.date} </span>
                                        <span className='fundamental-autor'>{fundamental.author}</span>
                                    </p>
                                    <Link href={fundamental.slug} className=''>Ver más</Link>
                                </div>
                                <Link href="/renta-variable" className='boton-lg azul'>Ver todo</Link>
                            </div>
                        }


                        { semanario &&
                            <div className="semanario">
                                <div className="semanario-head">
                                    <h2 className="semanario-title">Semanario Financiero</h2>
                                    <p className="semanario-date">{semanario.date}</p>
                                </div>
                                <div className="semanario-content">
                                    <div className="semanario-content-wrap">
                                        <h2 className="semanario-content-titulo">
                                            {semanario.title}
                                        </h2>
                                        <div className="semanario-actions">
                                            <a target="_blank" href={semanario.pdf} className='boton-md'>Abrir</a>
                                            <a href={semanario.pdf} className='boton-md' download={semanario.title} target="_blank">Descargar</a>
                                        </div>
                                    </div>
                                    <div className="semanario-thumb">
                                        <img src={Thumb} alt="" />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            presentacion &&
                            <div className="semanario">
                                <div className="semanario-head">
                                    <h2 className="semanario-title">Presentación Análisis</h2>
                                    <p className="semanario-date">{presentacion.date}</p>
                                </div>
                                <div className="semanario-content">
                                    <div className="semanario-content-wrap">
                                        <h2 className="semanario-content-titulo">
                                            {presentacion.title}
                                        </h2>
                                        <div className="semanario-actions">
                                            <a href={presentacion.pdf} className='boton-md' target="_blank">Abrir</a>
                                            <a href={presentacion.pdf} className='boton-md' download={presentacion.title} target="_blank">Descargar</a>
                                        </div>
                                    </div>
                                    <div className="semanario-thumb">
                                        <img src={Thumb} alt="" />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className='aside-social'>
                            <div className='title'>
                                <p>¡Síguenos en redes!</p>
                            </div>
                            <div className="links-social">
                                <ExternalLink href="https://www.facebook.com/vectoranalisis" >
                                    <img src={Face} alt="" />
                                </ExternalLink>
                                <ExternalLink href="https://www.instagram.com/vectoranalisis/" >
                                    <img src={Insta} alt="" />
                                </ExternalLink>
                                <ExternalLink href="https://mx.linkedin.com/company/vector-an%C3%A1lisis" >
                                    <img src={Linkedin} alt="" />
                                </ExternalLink>
                                <ExternalLink href="https://twitter.com/VectorAnalisis" >
                                    <img src={Tw} alt="" />
                                </ExternalLink>
                            </div>
                        </div>


                    </div>

                </section>
            </div>

            <CustomFooter openModal={openSuscribe}/>
            <GdprCookies />
            { open && <ModalSuscribe closeModal={closeSuscribe}/> }
        </>
    );
}
